<template>
  <div class="bookingBlock__content">
    <div class="bookingBlock__content-inner">
      <div class="paymentBooking">
        <div class="paymentBooking__content">
          <div class="paymentBooking__content-left">
            <div class="paymentBooking__title">Estimated Trip cost summary</div>
            <div class="paymentBooking__inner">
              <vue-scroll :ops="ops">
                <div class="paymentBooking__cost">
                  <CostBlock />
                  <!-- <router-link class="paymentBooking__cost-link" to="#">Split payment with group of X travelers
                  </router-link>
                  <router-link class="paymentBooking__cost-button" to="#" v-if="billing === 'detail'">
                    <span class="text">Transfer payment</span>
                  </router-link> -->
                </div>
              </vue-scroll>
            </div>
          </div>

          <div class="paymentBooking__content-right">
<!--            <BillingDetail @changeBilling="changeBilling"/>-->
            <!-- <BillingSelect v-if="billing === 'select'" @changeBilling="changeBilling"/> -->
            <!-- <BillingAddMethod v-if="billing === 'add-method'" @changeBilling="changeBilling"/> -->

            <Confirmation/>
          </div>
        </div>
      </div>

      <router-link class="bookingBlock__content-prev js-bookingNav-item" to="fly-green">
        <span class="icon">
          <SvgIcon name="left-arrow"/>
        </span>
        <span class="text">Back</span>
      </router-link>
<!--     <router-link class="bookingBlock__content-next js-bookingNav-item" to="confirmation">-->
<!--       <span class="icon">-->
<!--         <SvgIcon name="right-arrow"/>-->
<!--       </span>-->
<!--       <span class="text">Next</span>-->
<!--     </router-link>-->
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import CostBlock from '@/components/common/CostBlock/CostBlock'
  // import BillingDetail from '@/components/Billing/BillingDetail'
  import Confirmation from '@/views/booking/Confirmation/Confirmation';
  // import BillingSelect from '@/components/Billing/BillingSelect'
  // import BillingAddMethod from '@/components/Billing/BillingAddMethod'

  export default {
    name: 'payment',
    data: () => ({
      billing: 'detail',
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    methods: {
      changeBilling (billing) {
        this.billing = billing
      },
    },
    mounted () {
      if (document.querySelector('html').classList.contains('d-mobile')) {
        const transferButton = document.querySelector('.paymentBooking__cost-button')
        const transferContainer = document.querySelector('.paymentBooking__content')
        transferContainer.appendChild(transferButton)
      }
    },
    components: {
      Confirmation,
      SvgIcon,
      CostBlock,
      // BillingDetail,
      // BillingSelect,
      // BillingAddMethod
    },
  }
</script>
